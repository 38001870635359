import {
  useTripleWhaleCart,
  useTripleWhaleContact,
  useTripleWhalePageLoad,
} from './hooks';

const DEBUG = true;

export function TripleWhale() {
  useTripleWhaleCart({ DEBUG });
  useTripleWhaleContact({ DEBUG });
  useTripleWhalePageLoad({ DEBUG });

  return null;
} 