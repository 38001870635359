import { useEffect } from 'react';
import { useCartCheckout } from '@backpackjs/storefront';

export function useCheckout() {
  const { cartCheckout } = useCartCheckout();

  useEffect(() => {
    window.packGoToCheckout = () => {
      cartCheckout();
    };
  }, [cartCheckout]);
}