import { useEffect } from 'react';

export function useTripleWhaleCart({ DEBUG }) {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const addToCart = PubSub.subscribe('ITEM_ADDED_TO_CART', async (event, data) => {
      window.TriplePixel('AddToCart', {
        item: data.productId.replace('gid://shopify/Product/', ''),
        q: data.quantity || 1
      });
      
      if (DEBUG) console.log('TripleWhale:AddToCart', data);
    });

    return () => {
      if (addToCart) PubSub.unsubscribe(addToCart);
    };
  }, []);
} 