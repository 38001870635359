import { useEffect } from 'react';

export function useTripleWhaleContact({ DEBUG }) {
  useEffect(() => {
    if (typeof window === 'undefined') return;
    // add to CUSTOMER_REGISTERED and CUSTOMER_LOGGED_IN events 
    const customerRegistered = PubSub.subscribe('CUSTOMER_REGISTERED', async (event, data) => {
      window.TriplePixel('Contact', { email: data.email });
    });

    const customerLoggedIn = PubSub.subscribe('CUSTOMER_LOGGED_IN', async (event, data) => {
      window.TriplePixel('Contact', { email: data.email });
    });

    const subscribeEmail = PubSub.subscribe('SUBSCRIBE_EMAIL', async (event, email) => {
      window.TriplePixel('Contact', { email });
      if (DEBUG) console.log('TripleWhale:Contact:Email', { email });
    });

    const subscribePhone = PubSub.subscribe('SUBSCRIBE_PHONE', async (event, phone) => {
      window.TriplePixel('Contact', { phone });
      if (DEBUG) console.log('TripleWhale:Contact:Phone', { phone });
    });

    return () => {
      if (subscribeEmail) PubSub.unsubscribe(subscribeEmail);
      if (subscribePhone) PubSub.unsubscribe(subscribePhone);
    };
  }, []);
} 