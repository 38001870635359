import { useCallback, useState, useEffect, useRef } from 'react';
import { useCustomer, useSettings, useCountry } from '@backpackjs/storefront';
import { useGlobalContext } from '../../contexts';
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

// Global variable to track if the script has been loaded
let scriptLoaded = false;

export function BackInStockModal({ selectedVariant }) {
  const customer = useCustomer();
  const settings = useSettings();
  const { country } = useCountry();
  const {
    actions: { closeModal },
  } = useGlobalContext();

  const [email, setEmail] = useState(customer?.email || '');
  const [phone, setPhone] = useState('');
  const [notificationMethod, setNotificationMethod] = useState('SMS');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const { heading, subtext, submitText, confirmationMessage } = {
    ...settings?.product?.backInStock,
  };

  const [isSubscribed, setIsSubscribed] = useState(false);

  // Use a ref to track if the effect has run
  const scriptLoadedRef = useRef(false);

  useEffect(() => {
    // Check if the script hasn't been loaded globally and if this effect hasn't run yet
    if (!scriptLoaded && !scriptLoadedRef.current) {
      const script = document.createElement('script');
      script.src = 'https://forms-akamai.smsbump.com/774583/form_294066.js?ver=1715791120';
      script.async = true;
      script.onload = () => {
        scriptLoaded = true;
      };
      document.body.appendChild(script);

      // Mark this effect as having run
      scriptLoadedRef.current = true;
    }

    // Cleanup function to remove the script if the component unmounts
    return () => {
      if (scriptLoaded) {
        const existingScript = document.querySelector('script[src="https://forms-akamai.smsbump.com/774583/form_294066.js?ver=1715791120"]');
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
        scriptLoaded = false;
      }
    };
  }, []);

  useEffect(() => {
    if (country?.isoCode) {
      setPhone(`+${country.isoCode}`);
    }
  }, [country]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      let isValid = true;

      if (notificationMethod === 'SMS' || notificationMethod === 'SMS & Email') {
        if (!isPossiblePhoneNumber(phone)) {
          setPhoneError('Please enter a valid phone number');
          isValid = false;
        } else {
          setPhoneError('');
        }
      }

      // Remove email validation logic
      setEmailError('');

      if (!isValid) return;

      const channels = {
        'SMS': ['SMS'],
        'Email': ['EMAIL'],
        'SMS & Email': ['SMS', 'EMAIL'],
      }[notificationMethod];

      if (window.smsBumpBackInStock && channels.includes('SMS')) {
        const phoneNumber = parsePhoneNumber(phone);
        const payload = {
          phone: phone,
          email: notificationMethod === 'SMS & Email' ? email : undefined,
          country: phoneNumber.country || country?.isoCode || 'US',
          // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          customer_id: customer?.id,
          variant: {
            id: selectedVariant.legacyResourceId,
            title: selectedVariant.title,
            sku: selectedVariant.sku,
          },
          product: {
            id: selectedVariant.product.legacyResourceId,
            title: selectedVariant.product.title,
          }
        }
        window.smsBumpBackInStock.subscribe(payload);
        setIsSubscribed(true);
      }

      if (window.smsBumpBackInStock && channels.includes('EMAIL')) {
        const payload = {
          data: {
            type: 'back-in-stock-subscription',
            attributes: {
              profile: {
                data: {
                  type: 'profile',
                  attributes: {
                    email,
                  },
                },
              },
              channels: ['EMAIL'],
            },
            relationships: {
              variant: {
                data: {
                  type: 'catalog-variant',
                  id: `$shopify:::$default:::${selectedVariant.legacyResourceId}`,
                },
              },
            },
          },
        };

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            revision: '2023-12-15',
          },
          body: JSON.stringify(payload),
        };

        try {
          await fetch(
            `https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY}`,
            requestOptions
          );
          setIsSubscribed(true); // Set subscription status to true
        } catch (error) {
          console.error('Failed to subscribe to Klaviyo Back in Stock:', error);
        }
      }
    },
    [email, phone, notificationMethod, closeModal, selectedVariant, country, customer]
  );

  if (isSubscribed) {
    return (
      <div
        className="flex flex-col items-center gap-8 text-center"
        data-comp={BackInStockModal.displayName}
      >
        <div>
          <h2 className="text-title-h3">Thank you!</h2>
          <p className="mt-2">{confirmationMessage || "You'll be notified when this product is back in stock.'"}</p>
        </div>
        <button
          className="btn-primary mt-3 max-md:w-full"
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    );
  }

  return (
    <div
      className="flex flex-col items-center gap-8 text-center"
      data-comp={BackInStockModal.displayName}
    >
      <div>
        <h2 className="text-title-h3">{heading}</h2>
        {subtext && <p className="mt-2">{subtext}</p>}
      </div>

      <div>
        <h3 className="text-title-h4">{selectedVariant.product.title}</h3>
        <p>{selectedVariant.title}</p>
      </div>

      <form
        className="flex w-full flex-col items-center"
        onSubmit={handleSubmit}
      >
        <select
          className="input-text text-text mb-3 md:max-w-[30rem]"
          value={notificationMethod}
          onChange={(e) => setNotificationMethod(e.target.value)}
        >
          <option value="SMS">SMS</option>
          <option value="Email">Email</option>
          <option value="SMS & Email">SMS & Email</option>
        </select>

        {(notificationMethod === 'SMS' || notificationMethod === 'SMS & Email') && (
          <div className="w-full md:max-w-[30rem]">
            <PhoneInput
              className="input-text text-text mb-1"
              placeholder="Enter phone number"
              onChange={setPhone}
              required={notificationMethod !== 'Email'}
              international={true}
              withCountryCallingCode={true}
              countryOptionsOrder={["US", "CA", "GB", "|", "..."]}
              defaultCountry={country?.isoCode.toUpperCase()}
            />
            {phoneError && <p className="text-red-500 text-sm mb-2">{phoneError}</p>}
          </div>
        )}

        {(notificationMethod === 'Email' || notificationMethod === 'SMS & Email') && (
          <div className="w-full md:max-w-[30rem]">
            <input
              className="input-text text-text mb-1"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email..."
              required={notificationMethod !== 'SMS'}
              type="email"
              value={email}
            />
            {emailError && <p className="text-red-500 text-sm mb-2">{emailError}</p>}
          </div>
        )}

        <button
          aria-label="Notify Me"
          className="btn-primary mt-3 max-md:w-full"
          type="submit"
        >
          {submitText}
        </button>
      </form>
    </div>
  );
}

BackInStockModal.displayName = 'BackInStockModal';
